/* eslint-disable no-underscore-dangle */
export const types = {
  IBE: 'IBE',
  TYPEAHEAD: 'Typeahead',
  GEOCODE: 'Geocode',
  SEARCH_LOCATION: 'Search Location',
  MAP: 'Map',
  MAP_ZOOM: 'Map Zoom',
  MAP_PAN: 'Map Pan',
  MAP_CAMPSITE_MARKER: 'Map Campsite Marker',
  CAMPSITE_CARD: 'Campsite Card',
  AVAILABILITY: 'Availability',
  CALENDAR: 'Calendar',
  AVAILABILITY_DETAILS_FOOTER: 'Availability Details Footer',
  ADD_TO_BASKET: 'Add To Basket',
};

// get last timing that is not completed
const getLastTiming = (type) => (
  window.__timings.filter(t => (t.type === type && !t.completed)).pop()
);

const logTiming = (timingOfType, completed) => {
  if (timingOfType) {
    if (timingOfType.metrics && timingOfType.metrics.length === 0) {
      console.info('Timings', timingOfType.type, `- Start: ${new Date(timingOfType.start).toLocaleString().substring(12)}`);
    } else if (timingOfType.metrics && timingOfType.metrics.length > 0) {
      const lastMetric = timingOfType.metrics[timingOfType.metrics.length - 1];
      console.info('Timings', timingOfType.type, `- ${lastMetric.name}: ${lastMetric.delta}ms`);
    }

    // eslint-disable-next-line no-param-reassign
    timingOfType.completed = completed;
  }
};

// use to initialize a timing session of type
export const startTiming = (type) => {
  const start = new Date().getTime();
  const timingOfType = {
    type,
    start,
    metrics: [],
    completed: false,
  };
  window.__timings.push(timingOfType);
  logTiming(timingOfType, false);
};

export const hasMetric = (type, name) => {
  const timingOfType = getLastTiming(type);

  if (timingOfType && name) {
    const repeatedMetric
      = timingOfType.metrics?.findIndex(m => m.name === name) > -1;
    return repeatedMetric;
  }

  return false;
};

export const addTiming = (type, name, completed = false) => {
  if (typeof window !== 'undefined') {
    try {
      const alreadyDone = hasMetric(type, name);
      // gets the last timing object of that type
      const timingOfType = getLastTiming(type);

      if (timingOfType && !alreadyDone) {
        timingOfType.metrics.push({
          name,
          delta: new Date().getTime() - timingOfType.start,
        });

        logTiming(timingOfType, completed);
      }
    // eslint-disable-next-line no-empty
    } catch (e) {}
  }
};

if (typeof window !== 'undefined') {
  if (!window.__timings) {
    addTiming('IBE');
  }
}
