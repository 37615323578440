import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'graphql-anywhere';
import { compose, graphql, withApollo } from 'react-apollo';
import { withTheme } from 'styled-components';

import { Modal } from '../ui';
import SiteNightVoucherForm, { fragments } from './SiteNightVoucherForm';

import SiteNightVoucherModalStyled, {
  ButtonClose, Content, Title, Text,
} from './SiteNightVoucherModal.style';
import RichTextList from '../ui/RichText/RichTextList.style';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import { dataLayerManager } from '../../lib/dataLayer';
import IbePropTypes from '../../IbePropTypes';

const propTypes = {
  active: PropTypes.bool,
  amend: PropTypes.bool,
  client: PropTypes.shape({
    writeData: PropTypes.func.isRequired,
  }).isRequired,
  minimum: PropTypes.number,
  quote: PropTypes.shape(IbePropTypes.quote),
  siteNightVoucherInfo: PropTypes.shape({
    desktopImage: PropTypes.string,
    leftContent: PropTypes.string,
    rightContent: PropTypes.string,
    titleText: PropTypes.string,
    unitCost: PropTypes.number,
  }),
  theme: PropTypes.shape({
    COLOR_BRAND_PRIMARY_DARK: PropTypes.string,
  }),
  toggleBasket: PropTypes.func.isRequired,
};

const defaultProps = {
  active: false,
  amend: false,
  minimum: undefined,
  quote: undefined,
  siteNightVoucherInfo: {
    desktopImage: '',
    leftContent: '',
    rightContent: '',
    titleText: '',
    quantityAvailable: null,
    quantityMinimum: null,
    unitCost: null,
  },
  theme: {},
};

function SiteNightVoucherModal({
  client,
  siteNightVoucherInfo,
  toggleBasket,
  ...props
}) {
  if (!siteNightVoucherInfo) return null;

  if (props.active) {
    dataLayerManager.pushProductDetails(dataLayerManager.category.SITES, {
      name: siteNightVoucherInfo.titleText,
      id: dataLayerManager.bespokeProductIds.OVERSEAS_SITE_NIGHT_VOUCHERS,
      memberPrice: siteNightVoucherInfo.unitCost,
      category: dataLayerManager.category.SITES,
      subCategory: dataLayerManager.subCategory.EXTRA,
    }, 0);
  }

  return (
    <Modal active={props.active}>
      <SiteNightVoucherModalStyled>
        <ButtonClose
          crossWidth="1.875rem"
          onClick={() => client.writeData({
            data: { siteNightVoucherPopUp: { open: false, __typename: 'SiteNightVoucherPopUp', minimum: 0 } },
          })}
          width="4.125rem"
          height="4.125rem"
        />
        <Content
          backgroundColor={props.theme.COLOR_BRAND_PRIMARY_DARK}
          backgroundImage={siteNightVoucherInfo.desktopImage}
        >
          <Title tag={0} marginBottom>{siteNightVoucherInfo.titleText}</Title>
          <Text>{siteNightVoucherInfo.leftContent}</Text>
        </Content>
        <Content>
          <RichTextList>{siteNightVoucherInfo.rightContent}</RichTextList>
          <SiteNightVoucherForm
            amend={props.amend}
            quote={props.quote}
            siteNightVoucherInfo={
              filter(fragments.siteNightVoucherInfo, siteNightVoucherInfo)
            }
            toggleBasket={toggleBasket}
            minimum={props.minimum}
          />
        </Content>
      </SiteNightVoucherModalStyled>
    </Modal>
  );
}

SiteNightVoucherModal.propTypes = propTypes;
SiteNightVoucherModal.defaultProps = defaultProps;

export default compose(
  memo,
  withTheme,
  withApollo,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => data.configuration && ({ ...data.configuration }),
  }),
)(SiteNightVoucherModal);
