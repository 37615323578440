import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

import { updateQuoteCache } from '../../resolvers/quote';

import ButtonBrand from '../ui/Button/ButtonBrand.style';

import withStateMutation from '../../hocs/withStateMutation';

import UPDATE_QUOTE_EXTRAS from '../Quote/graphql/updateQuoteExtras.gql';
import UPDATE_AMEND_QUOTE from '../Quote/graphql/updateAmendQuote';
import UPDATE_AMEND_QUOTE_EXTRAS from '../Quote/graphql/updateAmendQuoteExtras.gql';
import UPDATE_QUOTE_MEMBERSHIP from '../Quote/graphql/updateQuoteMembership';
import UPDATE_QUOTE from '../Quote/graphql/updateQuote';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import GET_QUOTE from '../Quote/graphql/getQuote';
import quoteMembershipTypes, { getENumWithValue } from '../../config/quoteMembershipTypes';
import IbePropTypes from '../../IbePropTypes';
import { CAMC_MEMBER_PRODUCT_CODE, getProductWithCampsiteType } from '../../lib/campsiteTypes';
import { LoadingSpinner } from '../ui/Loading';
import { AddToBasketButton, ChildrenHidden, LoadingSpinnerWrapper } from './AddToBasket.style';
import { addTiming, startTiming, types } from '../../lib/timings';
import { BASKET_STATES } from '../../config/products';
import { errorCodes } from '../../lib/constants';

class AddToBasket extends Component {
  static propTypes = {
    data: PropTypes.shape({
      quote: PropTypes.shape(IbePropTypes.quote),
    }),
    'data-testid': PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    isExtra: PropTypes.bool,
    loading: PropTypes.bool,
    payload: PropTypes.shape({
      extras: PropTypes.arrayOf(PropTypes.shape({})),
      membershipType: PropTypes.number,
    }),
    onCompleted: PropTypes.func,
    onSubmit: PropTypes.func,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    render: PropTypes.func,
    updateQuote: PropTypes.func,
    updateAmendQuote: PropTypes.func,
    updateAmendQuoteExtras: PropTypes.func,
    updateQuoteExtras: PropTypes.func,
    handleCheckMembership: PropTypes.func,
    updateQuoteMembership: PropTypes.func,
    configuration: PropTypes.shape(IbePropTypes.configuration),
    membershipOnly: PropTypes.bool,
    block: PropTypes.bool,
    hidden: PropTypes.bool,
    validatePayload: PropTypes.func,
    onProductTypeMismatch: PropTypes.func,
    isAmend: PropTypes.bool,
  };

  static defaultProps = {
    data: {
      quote: {},
    },
    'data-testid': '',
    disabled: false,
    size: 'xl',
    children: null,
    isExtra: false,
    loading: false,
    payload: {},
    onCompleted: () => {},
    onSubmit: () => null,
    onError: () => {},
    onSuccess: () => {},
    render: null,
    updateQuote: () => {},
    updateAmendQuote: () => {},
    updateAmendQuoteExtras: () => {},
    updateQuoteExtras: () => {},
    updateQuoteMembership: () => {},
    handleCheckMembership: undefined,
    configuration: {},
    membershipOnly: false,
    block: true,
    hidden: false,
    validatePayload: null,
    onProductTypeMismatch: () => () => {},
    isAmend: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleAddToBasketClick = async (copiedPayload) => {
    const quote = this.props.data.quote || {};

    /*
    this does nothing really here, onSubmit sets state, and we move on after this has been set
    in QuoteCrossing
    */
    await this.props.onSubmit();

    const payload = copiedPayload;
    /*
    There can be situation when you do not have anything
    in the basket but your quote contains partyMembers:
    ex: You add crossing to the basket and then remove it
    (This is flaw in travelink), so this if statement is a workaroud.

    Without this piece of code, new users will be added the the existing users in the quote.
    This piece of code is adding the personId from the quote to an existing user in the payload.
    It only works when you have no campsite or crossings in the basket,
    and one partyMember in quote.
     */
    if (
      quote.campsiteBookings &&
      quote.crossingBookings &&
      !quote.campsiteBookings.length &&
      !quote.crossingBookings.length &&
      !this.props.isExtra &&
      quote.partyMembers.length === 1) {
      // TODO this is mutating props, should be changed.
      payload.partyMembers[0].personId = quote.partyMembers[0].personId;
    }

    if (payload.campsiteBooking?.campsite || payload.crossingBookings?.length) {
      const campsiteType = payload.campsiteBooking?.campsite?.type;
      const product = getProductWithCampsiteType(
        campsiteType,
        this.props.configuration?.products,
      );

      payload.productCode = product?.productCode;
    }

    let func = this.props.updateQuote;
    if (this.props.isExtra) {
      func = this.props.updateQuoteExtras;
    }
    if (this.props.membershipOnly) {
      func = this.props.updateQuoteMembership;
    }
    if (quote?.isBookingAmendmentQuote) {
      if (this.props.isExtra) {
        func = this.props.updateAmendQuoteExtras;
      } else {
        func = this.props.updateAmendQuote;
      }
    }
    await func(payload)
      .then(response => this.props.onSuccess(response))
      .catch(error => {
        const productMismatchErrorCode = errorCodes.BookingQuoteViewWrongProductRequested;
        if (error &&
          error.networkError &&
          error.networkError.result &&
          error.networkError.result.errorCode === productMismatchErrorCode) {
          const { onProductTypeMismatch } = this.props;
          const isOverseasProduct = payload.productCode === CAMC_MEMBER_PRODUCT_CODE;
          // if product is overseas and this error is returned,
          // this means the basket is not overseas, and vice versa
          const isBasketOverseas = isOverseasProduct ? BASKET_STATES.UK : BASKET_STATES.OVERSEAS;
          const quoteParam = quote?.basketState ? quote?.basketState : {
            basketState: isBasketOverseas,
          };
          onProductTypeMismatch(quoteParam)(isOverseasProduct);
          return null;
        }
        return this.props.onError(error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
        // TEMP Performance Hooks, TODO can be safely removed at any time
        addTiming(types.ADD_TO_BASKET, 'Update Quote Loaded');
        this.props.onCompleted();
      });
  };

  handleClick = () => {
    const {
      handleCheckMembership,
      isExtra,
      data,
      payload,
      membershipOnly,
      validatePayload,
    } = this.props;

    this.setState({
      isLoading: true,
    });

    if (validatePayload) {
      const isPayloadValid = validatePayload();
      if (!isPayloadValid) {
        this.setState({
          isLoading: false,
        });
        return;
      }
    }

    startTiming(types.ADD_TO_BASKET);

    const cb = this.handleAddToBasketClick;
    const membershipByDD = getENumWithValue(quoteMembershipTypes.MembershipByDD);
    const membershipByDDQuote = data.quote?.membershipType === membershipByDD;
    if (
      !handleCheckMembership &&
      payload.membershipType === membershipByDD &&
      !membershipByDDQuote &&
      !membershipOnly
    ) {
      localStorage.autoMember = 'true';
    }
    if (!isExtra && handleCheckMembership) {
      handleCheckMembership(cb, data.quote, payload);
    } else {
      cb(payload);
    }
  }

  render() {
    const {
      children, loading, size, disabled, render, hidden, block = true, isAmend,
    } = this.props;

    const isDisabled = loading || disabled;

    const buttonProps = {
      disabled: isDisabled,
      onClick: this.handleClick,
    };

    if (render) return this.props.render(buttonProps);

    const ButtonComponent = isAmend ? AddToBasketButton : ButtonBrand;

    return (
      <ButtonComponent
        block={block}
        size={size}
        hidden={hidden}
        type="button"
        id="addToBasket"
        data-testid={this.props['data-testid']}
        {...buttonProps}
      >
        <>
          {this.state.isLoading ? (
            <>
              <ChildrenHidden>
                {children || 'Add To Basket'}
              </ChildrenHidden>
              <LoadingSpinnerWrapper>
                <LoadingSpinner size="2rem" />
              </LoadingSpinnerWrapper>
            </>
          ) : (
            <>
              {children || 'Add To Basket'}
            </>
          )}
        </>
      </ButtonComponent>
    );
  }
}

export default compose(
  graphql(GET_QUOTE),
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      configuration: data.configuration,
      loading: data.loading,
    }),
  }),
  graphql(UPDATE_QUOTE, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateQuote: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  graphql(UPDATE_QUOTE_MEMBERSHIP, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateQuoteMembership: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  graphql(UPDATE_QUOTE_EXTRAS, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateQuoteExtras: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  graphql(UPDATE_AMEND_QUOTE_EXTRAS, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateAmendQuoteExtras: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  graphql(UPDATE_AMEND_QUOTE, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateAmendQuote: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  withStateMutation(
    { name: 'updateQuote' },
    { name: 'updateQuoteExtras' },
    { name: 'updateAmendQuoteExtras' },
    { name: 'updateAmendQuote' },
    { name: 'updateQuoteMembership' },
  ),
)(AddToBasket);
