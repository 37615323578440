import styled, { css } from 'styled-components';
import { below } from '../../../styles/libs';

import RichTextComponent from '.';

const RichTextList = styled(RichTextComponent)`
  width: 100%;
  max-width: 36rem;
  margin-bottom: 3rem;

  h2 {
    font-size: 1.25rem;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 2rem;
      margin: 0.5rem 0;
      font-weight: bold;

      label {
        display: block;
        font-weight: bold;
      }

      ${({ withLabel }) => withLabel && css`
        :not(label) {
          font-weight: normal;
        }
      `}

      &::before {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        width: 1.25em;
        height: 1.25em;
        margin-top: 0.5em;
        content: "";
        background-image: url("/static/images/icons/ListItemTick.svg");
      }
    }
  }

  p {
    font-size: 0.875rem;
    margin: 1.5em 0;
  }

  a {
    text-decoration: underline;
  }

  ${({ theme, withLabel }) => withLabel && css`
    ${below(theme.TABLET)`
      margin-top: 3rem;
    `}
  `}
`;

export default RichTextList;
