import gql from 'graphql-tag';

import quoteFragments from './quoteFragments';

export default gql`
${quoteFragments}

mutation UpdateQuote($input: QuoteUpdateMembershipInput) {
  quoteResponse: updateQuote(input: $input)
    @rest(
      type: "QuoteUpdate"
      path: "Reservations/UpdateQuoteMembership"
      method: "PATCH"
    ) {
      quote {
        basketState
        balanceDueDate
        basketHoldExpiryTimestamp
        basketHoldMaxTimestamp
        basketHoldStartTimestamp
        cancellationPenalty
        cancellationPenaltyApplicableFrom
        campsiteBookings {
          id
          campsite {
            id
            name
            address {
              country
              county
              addressLines
              postCode
            }
            siteCode
            type
          }
          pitches {
            arrivalDateTime
            id
            code
            type
            bookingDates {
              fromDate
              toDate
            }
            numNights
            errata
            basePrice
            totalPrice
            guests
            partyMemberReference
            supplements
            isNonRefundable
            isMemberExclusive
          }
        }
        crossingBookings @type(name: "CrossingBookings") {
          ...CrossingBooking
        }
        deposit
        departureDate
        errorCode
        extras {
          ...Extra
        }
        quoteId
        outfit {
          externalId
          id
          towHeight
          towLength
          towType
          towMake
          towModel
          vehicleHeight
          vehicleLength
          vehicleMake
          vehicleModel
          vehicleReg
          vehicleType
        }
        partyMembers {
          ...PartyMemberFrag
        }
        product
        status
        totalPrice
      }
    }
}
`;
