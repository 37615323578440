import styled from 'styled-components';
import { above } from '../../styles/libs';

import { Col as ColComponent } from '../ui/Grid';
import SelectComponent from '../ui/Form/Select';
import TextStyled from '../ui/Text';

export default styled.form``;

const wrapToRowBreakpoint = '1300px';

export const Select = styled(SelectComponent)`
  width: 100%;
  margin-right: 1rem;

  ${({ theme }) => above(theme.DESKTOP)`
    width: auto;
  `}
`;

export const Col = styled(ColComponent)`
  margin-bottom: 1rem;
  width: 100%;
  @media screen and (min-width: ${wrapToRowBreakpoint}}) {
    width: auto;
    &:first-child {
      flex-grow: 1.6;
    }
    &:last-child {
      flex-grow: 1;
    }
  }
`;

export const Row = styled.div`
  @media screen and (min-width: ${wrapToRowBreakpoint}}) {
    display: flex;
    flex-wrap: nowrap;
  }
`;

export const Text = styled(TextStyled)`
  margin-top: 1rem;
  font-size: 1rem;
  ${({ theme }) => above(theme.TABLET)`
    font-size: 1.25rem;
  `}
`;
