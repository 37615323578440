import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propType } from 'graphql-anywhere';
import { withRouter } from 'next/router';
import { compose, withApollo, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { format } from 'date-fns';

import { dictionaryItem } from '../../hocs/withDictionary';
import formatPrice from '../../lib/formatPrice';
import { DATE_FORMAT_DEFAULT } from '../../config/locale';
import GET_BOOKING_DETAILS from '../ManageMyBookings/graphql/getCurrentUserBooking.gql';
import GET_QUOTE from '../Quote/graphql/getQuote';
import { getBookingFromData } from '../ManageMyBookings/helpers';
import FetchPolicy from '../../constants/FetchPolicy';

import AddToBasket from '../AddToBasket';
import { Label } from '../ui/Form';
import testingAttr from '../../lib/testingAttr';
import EXTRA_TYPES from '../../config/extraTypes';

import SiteNightVoucherFormStyled, {
  Col,
  Row,
  Select,
  Text,
} from './SiteNightVoucherForm.style';
import { Text as TextComponent } from '../ui';

import { dataLayerManager } from '../../lib/dataLayer';
import ItxProvider from '../../lib/helpers/Itx/ItxProvider';
import quoteMembershipTypes, { getENumWithValue } from '../../config/quoteMembershipTypes';
import isLoggedIn from '../../lib/isLoggedIn';
import ErrorTypeMessage from '../ErrorTypeMessage';
import IbePropTypes from '../../IbePropTypes';
import routes from '../../constants/routes';

export const fragments = {
  siteNightVoucherInfo: gql`
    fragment UnitCost on SiteNightVoucherInfo {
      id
      quantityMaximum
      quantityMinimum
      unitCost
    }
  `,
};

class SiteNightVoucherForm extends Component {
  static propTypes = {
    amend: PropTypes.bool,
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    siteNightVoucherInfo: propType(fragments.siteNightVoucherInfo),
    toggleBasket: PropTypes.func.isRequired,
    minimum: PropTypes.number,
  }

  static defaultProps = {
    amend: false,
    quote: undefined,
    siteNightVoucherInfo: {
      id: null,
      quantityMaximum: 0,
      quantityMinimum: 0,
      unitCost: null,
    },
    minimum: undefined,
  }

  constructor(props) {
    super(props);

    const currentCount = props.quote?.extras?.find(
      (extra) => extra.type === EXTRA_TYPES.SITE_NIGHT_VOUCHER,
    )?.quantity;
    this.state = {
      quantity: currentCount || props.minimum || props.siteNightVoucherInfo.quantityMinimum,
    };
  }

  componentDidUpdate(prevProps) {
    const { siteNightVoucherInfo: { quantityMinimum: prevMinimum } = {} } = prevProps;
    const { siteNightVoucherInfo: { quantityMinimum } } = this.props;
    if (!prevMinimum && quantityMinimum) {
      this.updateQuantity();
    }
  }

  updateQuantity = () => {
    const { siteNightVoucherInfo: { quantityMinimum }, minimum } = this.props;
    this.setState({ quantity: minimum || quantityMinimum });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: Number(value) });
  }

  handleError = (error) => {
    this.setState({ error });
  }

  handleSuccess = async ({ data: { quoteResponse: { quote } } }) => {
    const { client, toggleBasket, amend } = this.props;

    dataLayerManager.pushAddToCart(dataLayerManager.subCategory.VOUCHER, {
      name: this.props.siteNightVoucherInfo.titleText,
      id: dataLayerManager.bespokeProductIds.OVERSEAS_SITE_NIGHT_VOUCHERS,
      price: this.props.siteNightVoucherInfo.unitCost,
      category: dataLayerManager.category.OVERSEAS,
      subCategory: dataLayerManager.subCategory.VOUCHER,
      quantity: this.state.quantity,
    });

    client.writeData({
      data: {
        siteNightVoucherPopUp: { open: false, __typename: 'SiteNightVoucherPopUp', minimum: 0 },
      },
    });

    const numberOfNights = ItxProvider.getMaxNightsFromQuote(quote);
    const showUpSell = ItxProvider.checkToShowPackageUpgradeDialog(
      quote,
      numberOfNights,
    );

    const upSellSaving = ItxProvider.getItxSaving(quote);

    if (showUpSell) {
      client.writeData({
        data: {
          upSellPopUp: {
            __typename: 'UpSellPopUp',
            open: true,
            saving: upSellSaving,
          },
        },
      });
    } else {
      client.writeData({
        data: {
          basketPopUp: {
            __typename: 'BasketPopUp',
            open: true,
            name: 'Site Night Vouchers',
          },
        },
      });
    }
    const response = await client.query({
      query: GET_BOOKING_DETAILS,
      fetchPolicy: FetchPolicy.CACHE_ONLY,
      notifyOnNetworkStatusChange: true,
      variables: {
        bookingReference: quote?.bookingReference,
        skip: !quote?.bookingReference,
      },
    });
    const bookingData = getBookingFromData(response?.data);
    if (amend && bookingData) {
      if (!bookingData.campsiteBookings?.length ||
        !quote?.bookingReference || !quote?.extras?.length) {
        return;
      }
      const booking = bookingData.campsiteBookings[0];
      const campsiteItem = booking.campsite;
      const bookingDates = booking.pitches[0]?.bookingDates;
      if (!campsiteItem || !bookingDates) {
        return;
      }
      const { fromDate, toDate } = bookingDates;
      const { id: campsiteId, siteCode } = campsiteItem;

      this.props.router.push({
        pathname: routes.sites,
        query: {
          campsiteId,
          end: format(toDate, DATE_FORMAT_DEFAULT),
          start: format(fromDate, DATE_FORMAT_DEFAULT),
          siteCode,
          bookingId: quote.bookingReference,
        },
      });
    }

    toggleBasket(true);
  }

  render() {
    const {
      quantityMaximum,
      quantityMinimum: configQuantityMinimum,
    } = this.props.siteNightVoucherInfo;
    const quantityMinimum = this.props.minimum || configQuantityMinimum;
    const length = quantityMaximum - quantityMinimum + 1;
    const totalPrice = this.state.quantity * this.props.siteNightVoucherInfo.unitCost;
    const disabled = quantityMaximum < quantityMinimum;
    const membershipType = getENumWithValue(
      isLoggedIn() ? quoteMembershipTypes.LoggedInMember :
        quoteMembershipTypes.MembershipByDD,
    );
    const currentCount = this.props.quote?.extras?.find(
      (extra) => extra.type === EXTRA_TYPES.SITE_NIGHT_VOUCHER,
    )?.quantity;
    const noneAdded = currentCount && this.state.quantity === currentCount;
    const quantityPayload = this.state.quantity;

    return (
      <SiteNightVoucherFormStyled>
        {!disabled &&
          <Label dictionary={dictionaryItem('SiteNightVoucherFormStyled', 'QtyVouchers')} />
        }
        <Row align="center">
          <Col>
            {!disabled &&
              <>
                <Select
                  name="quantity"
                  onChange={this.handleChange}
                  value={this.state.quantity}
                  {...testingAttr('site-night-voucher-form-quantity')}
                >
                  {Array.from({ length }, (v, n) => n + quantityMinimum).map(n => (
                    <option key={n} value={n}>{n}</option>
                  ))}
                </Select>
                <Text
                  size="1.25rem"
                  inline
                >
                  x {' '}
                  <span data-testid="site-night-voucher-form__unit-cost">
                    {formatPrice(this.props.siteNightVoucherInfo.unitCost)}
                  </span> {' '}
                  <b>Total {' '}
                    <span data-testid="site-night-voucher-form__total-cost">
                      {formatPrice(totalPrice)}
                    </span>
                  </b>
                </Text>
              </>
            }
            {disabled &&
              <TextComponent dictionary={dictionaryItem('SiteNightVoucherForm', 'NotAvailable')} />
            }
          </Col>
          <Col>
            <AddToBasket
              block
              disabled={disabled || noneAdded}
              onError={this.handleError}
              onSuccess={this.handleSuccess}
              payload={{
                extras: [
                  {
                    quantity: quantityPayload,
                    id: this.props.siteNightVoucherInfo.id,
                  },
                ],
                membershipType,
              }}
              isExtra
              {...testingAttr('site-night-voucher-form__add-to-basket-button')}
            />
          </Col>
        </Row>
        {this.state.error &&
          <ErrorTypeMessage error={this.state.error} marginBottom />
        }
      </SiteNightVoucherFormStyled>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  graphql(GET_QUOTE, {
    props: ({ data }) => ({
      quote: data.quote,
      loading: data.loading,
    }),
  }),
)(SiteNightVoucherForm);
