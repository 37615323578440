import styled, { css } from 'styled-components';
import { above, below } from '../../styles/libs';
import detectiOS from '../../lib/detectiOS';

import ButtonCloseComponent from '../ui/Button/ButtonClose';
import TextStyled from '../ui/Text';
import TitleStyled from '../ui/Title';

export default styled.div`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  z-index: 1;
  ${({ theme }) => css`
    ${above(theme.TABLET)`
      flex-direction: row;
      overflow-y: none;
    `}
  `}
  ${({ theme }) => below(theme.TABLET) && !!detectiOS() && css`
    padding-bottom: 5rem;
  `}
`;

export const ButtonClose = styled(ButtonCloseComponent)`
  top: 0;
  right: 0;
  height: 3rem;
  margin: 1rem 1rem 0;
  width: 3rem;
  position: fixed;
  z-index: 1;

  ${({ theme }) => css`
    ${above(theme.TABLET)`
      height: 5rem;
      margin: 2rem 4rem 2rem 2rem;
      width: 5rem;
    `}
  `}
`;

export const Content = styled.div`
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.COLOR_GRAY_LIGHTER};
  ${({ backgroundImage }) => backgroundImage && css`
    background-image: url(${backgroundImage});
    background-position: center;
    background-size: cover;
  `}
  flex: 1;
  overflow-y: none;
  padding: 2rem;
  ${({ theme }) => css`
    ${above(theme.TABLET)`
      overflow-y: auto;
      padding: 5rem;
    `}
  `}
`;

export const Text = styled(TextStyled)`
  color: ${({ theme }) => theme.COLOR_WHITE};
  font-size: 1.25rem;
  max-width: 24rem;
  width: 100%;
`;

export const Title = styled(TitleStyled)`
  color: ${({ theme }) => theme.COLOR_WHITE};
  max-width: 24rem;
  margin: 2rem 0 1rem 0;
  width: 100%;
  ${({ theme }) => css`
    ${above(theme.TABLET)`
      margin: 0 0 2rem 0;
    `}
  `}
`;
