import { getPhase, PHASE_1, PHASE_2 } from './phase';

const phase = getPhase();

export const FEATURE_FLAGS = {
  BOOK_UK_CAMPSITES: 'BOOK_UK_CAMPSITES',
  SHOW_PITCH_TYPES: 'SHOW_PITCH_TYPES',
  UK_SITES: 'UK_SITES',
  MAP: 'MAP',
};

/**
 * Evaluate phase etc to determine if supported, supported === true.
 */
const featureFlags = {
  [FEATURE_FLAGS.BOOK_UK_CAMPSITES]: phase >= PHASE_2,
  [FEATURE_FLAGS.SHOW_PITCH_TYPES]: phase >= PHASE_2,
  [FEATURE_FLAGS.SAVE_QUOTE]: phase >= PHASE_2,
  [FEATURE_FLAGS.UK_SITES]: phase >= PHASE_2,
  [FEATURE_FLAGS.MAP]: phase >= PHASE_1,
};

export function getFeatureFlag(key) {
  if (!key) {
    return false;
  }

  return !!featureFlags[key];
}

export default featureFlags;
