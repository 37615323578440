import styled, { css } from 'styled-components';
import { ButtonBrand } from '../ui/Button';

export const ChildrenHidden = styled.div`
  opacity: 0;
`;

export const LoadingSpinnerWrapper = styled.div`
  position: absolute;
`;

export const AddToBasketButton = styled(ButtonBrand)`
  &:disabled {
    opacity: 0.7;
    background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
    color: ${({ theme }) => theme.COLOR_WHITE};
  }
  ${({ disabled, theme }) => disabled && css`
    opacity: 0.7;
    background-color: ${theme.COLOR_BRAND_PRIMARY_DARK};
    color: ${theme.COLOR_WHITE};
  `}
`;
