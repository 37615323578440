import React from 'react';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import { withTheme } from 'styled-components';

import { Select } from '../../ui/Form';
import Text from '../../ui/Text';
import Title from '../../ui/Title';
import { dictionaryItem, getDictionaryItem } from '../../../hocs/withDictionary';
import IbePropTypes from '../../../IbePropTypes';
import MessageWarning from '../../ui/Message/MessageWarning.style';

const BOOKING_REFERENCE_KEY = '{{BookingReference}}';
const CAMPSITE_NAME_KEY = '{{CampsiteName}}';

const CancellationReasonForm = ({
  bookingId, campsiteName, client, theme, options,
  value, onChange, showReasons, showTitle, error,
}) => {
  let message = getDictionaryItem(
    client, campsiteName ?
      'Amend__Modal__CancelStay__Text' : 'Amend__Modal__CancelBooking__Text',
  );
  message = message.replace(campsiteName ? CAMPSITE_NAME_KEY : BOOKING_REFERENCE_KEY,
    campsiteName || bookingId);

  return (
    <>
      {showTitle &&
      <Text
        marginBottom="1rem"
        size="0.85rem"
      >
        {message}
      </Text>}
      {showReasons &&
        <>
          <Title
            dictionary={dictionaryItem('Amend', 'Modal', 'CancellationReason')}
            tag={1}
            size={4}
          />
          <Text
            dictionary={dictionaryItem('Amend', 'Modal', 'CancellationReason')}
            marginTop="0.25rem"
            marginBottom="0.5rem"
            size="0.85rem"
          />
          <Select
            id="cancellationReason"
            onChange={onChange}
            type="number"
            value={value}
            block={!showTitle}
          >
            <option value="" disabled={!!value}>Please select</option>
            {options.map(type => (
              <option key={type.id} value={type.id}>{type.description}</option>
            ))}
          </Select>
        </>
      }
      {error && (
        <MessageWarning
          marginTop
          marginBottom
          dictionary={dictionaryItem('Amend', 'Modal', 'CancellationReason', 'Error')}
        />
      )}
      <Text
        dictionary={dictionaryItem('Amend', 'Modal', 'CancellationReason', 'Info')}
        marginTop="1rem"
        size="0.85rem"
        color={theme.COLOR_BRAND_PRIMARY}
        weight="bold"
      />
    </>
  );
};

CancellationReasonForm.propTypes = {
  bookingId: PropTypes.string.isRequired,
  campsiteName: PropTypes.string,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  theme: PropTypes.shape({
    COLOR_BRAND_PRIMARY: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showReasons: PropTypes.bool,
  showTitle: PropTypes.bool,
  error: PropTypes.bool,
};

CancellationReasonForm.defaultProps = {
  campsiteName: '',
  showReasons: true,
  showTitle: true,
  error: false,
};

export default compose(
  withApollo,
  withTheme,
)(CancellationReasonForm);
